const MATCH_SLUG_REGEXP = /\/(.*?)\/?$/;

/**
 * Extracts the download id from the given slugs.
 *
 * @param slugs The slugs to use.
 * @param documentCenterSlug The document center slug.
 */
export function findDownloadIdInSlug(
  slugs: ReadonlyArray<string>,
  documentCenterSlug: string
): string | undefined {
  // e.g. sth. like "downloads/documents"
  const downloadSlug = slugs.filter(Boolean).join('/');

  return downloadSlug
    .replace(documentCenterSlug, '')
    .replace(MATCH_SLUG_REGEXP, '$1');
}
